<template>
  <div class="iq-card mb-3" style="min-width: 300px">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title py-3">
        <h4 class="card-title">Role Detail</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-right">
            <button-options
              :access="canRead('master_role')"
              :hideDetail="true"
              :isBackEnabled="true"
              :isRefreshEnabled="true"
              :refreshed="isLoading"
              @onRefreshed="getUserRoles('Refreshed')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="roleId" class="d-block form-label text-left">
            Role:
          </label>
          <div class="bv-no-focus-ring">
            <v-select
              id="roleId"
              label="roleName"
              v-model="data.usroRoleId"
              placeholder="Select Role"
              :options="roles"
              :disabled="isSubmit"
              :reduce="(role) => role.roleId"
              :class="errors.usroRoleId ? 'is-invalid' : ''"
              @search="filterRoles"
            >
              <template slot="no-options">
                {{
                  roles.length > 0 ? 'Type to search role' : 'No role available'
                }}
              </template>
            </v-select>
          </div>
          <b-form-invalid-feedback v-if="errors.usroRoleId">
            {{ Object.values(errors.usroRoleId) }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <b-button
            class="ml-1"
            size="sm"
            type="submit"
            variant="primary"
            :disabled="isSubmit"
            @click="onRoleSubmitClicked"
          >
            <div class="mx-2">
              <i
                class="fa"
                :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"
              ></i>
              Submit User Role Data
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <b-table
              fixed
              show-empty
              responsive
              :busy="isLoading"
              :fields="['usroRole.roleName', 'action']"
              :items="userRoles"
              head-variant="dark"
              style="border-radius: 10px"
            >
              <template v-slot:table-busy="">
                <div class="text-center text-danger my-2">
                  <b-spinner
                    variant="success"
                    size="sm"
                    class="align-middle"
                  ></b-spinner>
                </div>
              </template>
              <template v-slot:head(usroRole.roleName)="">
                <div class="text-left">List of User Role</div>
              </template>
              <template v-slot:head(action)="">
                <div class="text-center"></div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="text-left">
                  <button
                    class="btn btn-light m-1 btn-sm"
                    :disabled="isSubmit"
                    @click="openDelete(data.item, 'Role')"
                  >
                    <i class="ri-delete-bin-7-line"></i>
                    Delete
                  </button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div class="col-md-12">
          <b-pagination
            v-model="role.pagination.current"
            :per-page="role.pagination.pageSize"
            :total-rows="role.pagination.totalSize"
            @change="onRolePaginationChanged"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      v-model="displayDeletion"
    >
      <delete-item
        :isSubmit="isSubmit"
        @onCancelDeletion="displayDeletion = false"
        @onConfirmDeletion="onConfirmDeletion"
      />
    </b-modal>
  </div>
</template>

<script>
import RoleSvc from '@/services/RoleSvc';
import UserSvc from '@/services/UserSvc';
import requestBuilder from '@/util/request.builder';
import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';

export default {
  name: 'UserRoleDetail',
  components: {
    ButtonOptions,
    DeleteItem,
  },

  data() {
    return {
      data: {},
      displayDeletion: false,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      errors: {},
      filter: {
        userId: this.$route.params.id,
      },
      id: this.$route.params.id,
      related: {
        companies: [],
        deleteType: undefined,
        table: {
          companies: [],
        },
      },
      selected: {},
      pagination: {
        current: 1,
        pageSize: 25,
        totalSize: 0,
      },
      role: {
        pagination: {
          current: 1,
          pageSize: 25,
          totalSize: 0,
        },
      },
      roles: [],
      userRoles: [],
    };
  },
  methods: {
    async getUserRoles(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const filter = {
          usroUserId: this.id,
        };
        const res = await RoleSvc.getUserRoles({
          params: {
            ...requestBuilder.buildFilters(this.role.pagination, filter),
          },
        });
        if (notification) {
          this.$toast.success(notification);
        }
        this.userRoles = res.data.data;
        this.role.pagination.totalSize = res.data.pagination.totalData;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async getRoles() {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        const res = await UserSvc.getRoles({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        this.related.roles = res.data.data;
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    getAvailableRoles() {
      this.roles = [];
      const unAvailableRoles = this.userRoles.map((o) => o.usroRole.roleId);
      const roles = Object.values(this.related.roles);
      for (let i = 0; i < unAvailableRoles.length; i++) {
        const findIndex = roles.findIndex(
          (o) => o.roleId === unAvailableRoles[i],
        );
        roles.splice(findIndex, 1);
      }
      this.roles = roles;
    },
    onRolePaginationChanged(page) {
      this.role.pagination.current = page;
      this.getUserRoles();
    },
    // eslint-disable-next-line no-unused-vars
    async filterRoles(v, loading) {
      const param = {
        findField: 'roleName',
        findValue: v,
      };
      loading = true;
      try {
        const res = await UserSvc.getRoleByName(param);
        if (res.data.status.error === 0) {
          this.permissions = res.data.data;
        }
        loading = false;
      } catch (e) {
        loading = false;
        this.$toast.error(e.message);
      }
    },
    async onRoleSubmitClicked() {
      const data = {
        usroRoleId: this.data.usroRoleId,
        usroUserId: this.id,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        const res = await UserSvc.addUserRole(data);
        if (res.data.status.error === 0) {
          await this.getRoles();
          await this.getUserRoles('Role added');
          await this.getAvailableRoles();
        } else {
          const errors = res.data.status.message;
          if (typeof errors === 'object') {
            this.errors = res.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(res.data.status.message);
          }
        }
        this.pagination.current = 1;
        this.selected = {};
        this.data.usroRoleId = null;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
      } finally {
        this.isSubmit = false;
      }
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    onConfirmDeletion() {
      this.deleteData('Delete complete', this.selected);
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const res = await UserSvc.deleteUserRole(data.usroId);
        if (res.data.status.error === 0) {
          this.displayDeletion = false;
          this.pagination.current = 1;
          await this.getRoles();
          await this.getUserRoles();
          await this.getAvailableRoles();
        } else {
          this.$toast.error(res.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
  },
  async mounted() {
    await this.getRoles();
    await this.getUserRoles();
    await this.getAvailableRoles();
  },
};
</script>
