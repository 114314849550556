<template>
  <div>
    <div class="wrapper">
      <b-navbar toggleable="lg" type="light">
        <b-navbar-brand :to="{ name: 'Home' }">
          <img :src="logo" alt="Logo" class="d-inline-block align-top">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav >
          <b-navbar-nav v-if="activeApp?.name === 'fms'">
            <!-- Home Link -->
            <b-nav-item :to="{ name: 'Home' }" :class="{ 'active': $route.name === 'Home' }"
              class="d-flex align-items-center">
              <img :src="$route.name === 'Home' ? icon.IconHomeActive : icon.IconHome" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Home' }">Home</span>
            </b-nav-item>
            <!--/ Home Link -->

            <!-- Dashboard Link -->
            <b-nav-item :to="{ name: 'FmsDashboard' }" :class="{ 'active': $route.name === 'FmsDashboard' }"
              class="d-flex align-items-center">
              <img :src="icon.IconDashboard" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'FmsDashboard' }">Dashboard</span>
            </b-nav-item>
            <!--/ Dashboard Link -->

            <!-- Report Dropdown -->
            <b-nav-item-dropdown :class="{ 'active': $route.name === 'FmsReport' || $route.name === 'FmsDatalog' }"
              text="Report" class="d-flex align-items-center" right>
              <template #button-content>
                <img :src="icon.IconReport" class="mr-2" />
                <span :class="{ 'pink-text': $route.name === 'FmsReport' }">Report</span>
              </template>
              <b-dropdown-item :to="{ name: 'FmsReport' }">
                Report Summary
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'FmsDatalog' }">
                Report Datalog
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <!--/ Report Dropdown -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Asset' }" :class="{ 'active': $route.name === 'Asset' }"
              class="d-flex align-items-center">
              <img :src="icon.IconAsset" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Asset' }">Asset</span>
            </b-nav-item>
            <!--/ Asset Link  -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Device' }" :class="{ 'active': $route.name === 'Device' }"
              class="d-flex align-items-center">
              <img :src="icon.IconDevice" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Device' }">Device</span>
            </b-nav-item>
            <!--/ Asset Link  -->
          </b-navbar-nav>

          <b-navbar-nav v-if="activeApp?.name === 'bunkering'">
            <!-- Home Link -->
            <b-nav-item :to="{ name: 'Home' }" :class="{ 'active': $route.name === 'BunkerDashboard' }"
              class="d-flex align-items-center">
              <img :src="$route.name === 'Home' ? icon.IconHomeActive : icon.IconHome" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Home' }">Home</span>
            </b-nav-item>
            <!--/ Home Link -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Asset' }" :class="{ 'active': $route.name === 'Asset' }"
              class="d-flex align-items-center">
              <img :src="icon.IconAsset" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Asset' }">Asset</span>
            </b-nav-item>
            <!--/ Asset Link  -->

            <!-- Asset Link -->
            <b-nav-item :to="{ name: 'Device' }" :class="{ 'active': $route.name === 'Device' }"
              class="d-flex align-items-center">
              <img :src="icon.IconDevice" class="mr-2" />
              <span :class="{ 'pink-text': $route.name === 'Device' }">Device</span>
            </b-nav-item>
            <!--/ Asset Link  -->
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">

            <!-- Select Company -->
            <b-nav-item text="Select Company" class="d-flex align-items-center" :to="{ name: 'CompanyActiveSelection' }"
              right>
              <img :src="icon.IconCompany" class="mr-2">
              <span>Select Company</span>
            </b-nav-item>
            <!--/ Select Company -->

            <!-- dropdown user -->
            <b-nav-item-dropdown class="d-flex align-items-center" right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <img :src="profileImage" class="mr-2" :style="{ width: '25px', height: '25px' }">
                <span class="truncate">{{ userId }}</span>
              </template>
              <b-dropdown-item v-if="userAccess.master_user && userAccess.master_user.find((x) => x === 'read')" :to="{ name: 'User' }">
                Users List
              </b-dropdown-item>
              <b-dropdown-item v-if="userAccess.master_role && userAccess.master_role.find((x) => x === 'read')" :to="{ name: 'Role' }">
                Role
              </b-dropdown-item>
              <b-dropdown-item v-if="userAccess.master_group_company && userAccess.master_group_company.find((x) => x === 'read')" :to="{ name: 'Group' }">
                Group
              </b-dropdown-item>
              <b-dropdown-item v-if="userAccess.master_company && userAccess.master_company.find((x) => x === 'read')" :to="{ name: 'Company' }">
                Company
              </b-dropdown-item>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item @click="handleLogout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
            <!--/ dropdown user -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <div>
        <transition name="router-anim">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/Mantraz-logo.svg';
import profileImage from '@/assets/images/user/default-user.png';
import { sofbox } from '@/config/pluginInit';

import IconHome from '@/assets/images/navbar/home.svg';
import IconHomeActive from '@/assets/images/navbar/home-active.svg';
import IconDashboard from '@/assets/images/navbar/dashboard.svg';
import IconDashboardActive from '@/assets/images/navbar/dashboard-active.svg';
import IconReport from '@/assets/images/navbar/report.svg';
import IconReportActive from '@/assets/images/navbar/report-active.svg';
import IconAsset from '@/assets/images/navbar/asset.svg';
import IconAssetActive from '@/assets/images/navbar/asset-active.svg';
import IconCompany from '@/assets/images/navbar/company.svg';
import IconDevice from '@/assets/images/navbar/device.svg';

export default {
  name: 'AuthorizedLayoutTop',
  components: {
  },
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
    userAccess() {
      const access = this.$store.getters['auth/userAccess'];
      return access;
    },
    userId() {
      return this.$store.getters['auth/userId'];
    },
    activeApp() {
      return this.$store.getters['auth/activeApp'];
    },
  },
  data() {
    return {
      logo,
      profileImage,
      isSmallSidebar: false,
      icon: {
        IconHome,
        IconHomeActive,
        IconDashboard,
        IconDashboardActive,
        IconReport,
        IconReportActive,
        IconAsset,
        IconAssetActive,
        IconCompany,
        IconDevice,
      },
    };
  },
  mounted() {
    sofbox.mainIndex();
  },
  methods: {
    onMaxMinSidebar() {
      this.isSmallSidebar = !this.isSmallSidebar;
    },
    handleLogout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/auth/login');
    },
  },
};
</script>
<style>
.truncate {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar-brand img {
  width: 72px;
}

.navbar {
  background-color: white;
  padding: 0 1rem !important;
  height: 70px;
  z-index: 999;
}

.navbar-collapse {
  height: 100%;
}

.navbar-nav {
  height: 100%;
}

.user-profile-image {
  width: 25px;
}

.nav-item {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-link {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.nav-link img {
  height: 14px;
}

.nav-link span {
  color: black;
  font-weight: 600;
  letter-spacing: 1.4px;
}

.active {
  color: #ff69b4;
  position: relative;
}

.active::after {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 2px;
  height: 6px;
  bottom: 0px;
  left: 0;
  background-color: #ff69b4;
}

.pink-text {
  color: #ff69b4 !important;
}
</style>
