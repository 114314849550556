<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="mt-3">
    <div class="iq-card mb-3 mx-3" style="min-width: 300px;">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">Edit User</h4>
        </div>
      </div>
      <div class="iq-card-body">
        <div class="row">
          <div role="group" class="form-group col-md-6">
            <label for="userId" class="d-block form-label text-left">
              Email:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input class="form-control" :disabled="data.userCreatedTime" type="text" id="userId" placeholder="Email"
                  v-model="data.userId" :class="errors.userId ? 'is-invalid' : ''">
                <b-form-invalid-feedback v-if="errors.userId">
                  {{ Object.values(errors.userId) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="userStatus" class="d-block form-label text-left">
              Status:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" id="userStatus" class="input-group">
                <select class="form-control" v-model="data.userStatus" :disabled="isSubmit"
                  :class="errors.userStatus ? 'is-invalid' : ''">
                  <option value="1">Approved</option>
                  <option value="0">Not Approved</option>
                </select>
                <b-form-invalid-feedback v-if="errors.userStatus">
                  {{ Object.values(errors.userStatus) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="userId" class="d-block form-label text-left">
              Username:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input class="form-control" type="text" id="username" placeholder="username" disabled
                  v-model="usloData.usloUserName" :class="errors.usloUserName ? 'is-invalid' : ''">
                <b-form-invalid-feedback v-if="errors.usloUserName">
                  {{ Object.values(errors.usloUserName) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div role="group" class="form-group col-md-6">
            <label for="userId" class="d-block form-label text-left">
              password:
            </label>
            <div class="bv-no-focus-ring">
              <div role="group" class="input-group">
                <input class="form-control" type="password" id="usloPassword" placeholder="password"
                  v-model="usloData.usloPassword" :class="errors.usloPassword ? 'is-invalid' : ''">
                <b-form-invalid-feedback v-if="errors.usloPassword">
                  {{ Object.values(errors.usloPassword) }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group text-right">
          <b-button type="submit" @click="onSubmitEditClicked('Updated')" :disabled="isSubmit" variant="primary"
            size="sm" class="ml-1">
            <div class="mx-2">
              <i class="fa" :class="isSubmit ? 'ri-loader-line' : 'ri-save-line'"></i>
              Update User Data
            </div>
          </b-button>
        </div>
      </div>
    </div>
    <div class="px-3">
      <b-tabs content-class="mt-3">
        <b-tab title="User Company" active>
          <user-company-detail />
        </b-tab>
        <b-tab title="User Role">
          <user-role-detail />
        </b-tab>
        <b-tab title="User Asset">
          <user-asset-detail />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import UserSvc from '@/services/UserSvc';
import requestBuilder from '@/util/request.builder';

import UserCompanyDetail from './UserCompanyDetail.vue';
import UserRoleDetail from './UserRoleDetail.vue';
import UserAssetDetail from './UserAssetDetail.vue';

export default {
  name: 'UserDetail',
  components: {
    UserCompanyDetail,
    UserRoleDetail,
    UserAssetDetail,
  },
  data() {
    return {
      data: {},
      usloData: {},
      errors: {},
      filter: {
        userId: this.$route.params.id,
      },
      id: this.$route.params.id,
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 10,
        totalSize: 0,
      },
    };
  },
  methods: {
    async getData(notification) {
      this.isLoading = true;
      this.isRefreshed = true;
      try {
        if (notification) this.$toast.success(notification);
        const res = await UserSvc.getUsers({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        const usloRes = await UserSvc.getUserLogin({
          ...requestBuilder.buildFilters(this.pagination, this.filter),
        });

        this.data = res.data.data.find((userData) => userData.userId === this.id);
        this.usloData = usloRes.data.data.find((userData) => userData.usloUserId === this.id);
        this.usloData.usloPassword = undefined;
      } catch (e) {
        this.$toast.error('Error when fetching user data');
        console.error(e);
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async onSubmitEditClicked(notification) {
      try {
        const userData = {
          userId: this.data.userId,
          userStatus: this.data.userStatus,
        };

        const usloData = {
          usloUserId: this.data.userId,
          usloUserName: this.usloData.usloUserName,
          usloPassword: this.usloData.usloPassword,
        };
        if (!usloData.usloPassword) delete usloData.usloPassword;

        this.errors = {};
        this.isSubmit = true;

        await Promise.all([
          UserSvc.update(this.id, userData),
          UserSvc.updateUserLogin(this.id, usloData),
        ]);

        await this.getData(notification);

        // if (res.data.status.error === 0) {
        // } else {
        //   const errors = res.data.status.message;
        //   if (typeof errors === 'object') {
        //     this.errors = res.data.status.message;
        //     this.$toast.error('Error found');
        //   } else {
        //     this.$toast.error(res.data.status.message);
        //   }
        // }
      } catch (e) {
        this.$toast.error('Error when submitting');
      } finally {
        this.isSubmit = false;
      }
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style scoped></style>
