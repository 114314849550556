import axios from 'axios';

export default {
  async getPulse(devices, type) {
    try {
      const response = await axios.get(`/series/device/${type}/pulse`, { params: { device: devices } });
      return response?.data?.data?.series || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
};
