<template>
  <b-container fluid>
    <div class="iq-card mt-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title my-2">
          <h4 class="card-title">
            {{ massName ? massName : 'All Asset' }} Device List
          </h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options
                :access="'master_device'"
                :hideAdd="false"
                :hideDetail="true"
                :isRefreshEnabled="true"
                :isRefreshed="isRefreshed"
                @onAddItemClicked="openAddForm"
                @onRefreshed="getData('Refreshed')"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row class="mb-3">
          <b-col md="3" v-if="!massId">
            <input-selector
              :data="assets"
              :id="'massId'"
              :label="'massName'"
              :loading="isLoading"
              :placeholder="'Please select asset'"
              @onChangeSelector="onChangeAsset"
            />
          </b-col>
          <b-col md="3">
            <input-selector
              :data="devcTypes"
              :id="'value'"
              :label="'name'"
              :loading="isLoading"
              :placeholder="'Please select device type'"
              @onChangeSelector="onChangeDeviceType"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <device-list
              :access="'master_device'"
              :data="data"
              :loading="isLoading"
              :pagination="pagination"
              @onActionDelete="openDelete"
              @onActionEdit="openEdit"
              @onActionView="openDetail"
              @onPageChanged="onPageChanged"
              @update="onUpdated"
            />
          </b-col>
        </b-row>
      </div>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayForm"
      >
        <device-form
          :data="currentDevice"
          :errors="errors"
          :isSubmit="isSubmit"
          @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel"
        />
      </b-modal>
      <b-modal
        size="lg"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        v-model="displayDeletion"
      >
        <DeleteItem
          :isSubmit="isSubmit"
          @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion"
        />
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import DeviceForm from '@/components/device/Form.vue';
import DeviceList from '@/components/device/List.vue';
import DeviceSvc from '@/services/DeviceSvc';

import ButtonOptions from '@/components/directives/button-options';
import DeleteItem from '@/components/directives/delete-item';
import InputSelector from '@/components/directives/input-selector';

import requestBuilder from '@/util/request.builder';
import AssetSvc from '@/services/AssetSvc';

export default {
  name: 'Device',
  components: {
    DeleteItem,
    InputSelector,
    ButtonOptions,
    DeviceForm,
    DeviceList,
  },
  data() {
    return {
      massId: this.$route.params.id || null,
      massName: null,
      assets: [],
      currentDevice: {},
      data: [],
      devcTypes: [
        { name: 'GPS', value: 'gps' },
        { name: 'Flowmeter', value: 'flowmeter' },
        { name: 'RPM', value: 'rpm' },
        { name: 'Auxialary Engine', value: 'ae' },
        { name: 'Gateway', value: 'gateway' },
        { name: 'Power Supply', value: 'power' },
      ],
      displayForm: false,
      displayDeletion: false,
      errors: {},
      deviceFilter: {
        devcMassId: [],
      },
      assetFilter: {
        massCompId: null,
      },
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 25,
        totalSize: 0,
      },
    };
  },
  methods: {
    async getAssets() {
      const assetResponse = await AssetSvc.getAssets({
        params: {
          ...requestBuilder.buildFilters(this.pagination, this.assetFilter),
        },
      });
      this.assets = assetResponse.data.data;
    },
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const deviceDeleteResponse = await DeviceSvc.deleteDevice(data.devcId);
        if (deviceDeleteResponse.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = await false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(deviceDeleteResponse.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;

        let deviceParams = {};

        // menambahkan params findField dan findValue jika ada parameter
        if (this.massId) {
          deviceParams = {
            'finds[devcMassId]': this.massId,
          };
        }

        const deviceResponse = await DeviceSvc.getDevices({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.deviceFilter),
            ...deviceParams,
          },
        });

        this.data = deviceResponse.data.data;
        this.pagination.totalSize = deviceResponse.data.pagination.totalData;

        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
      }
    },
    async onSubmitClicked(form) {
      const data = {
        devcName: form.devcName,
        devcLabel: form.devcLabel,
        devcType: form.devcType,
        devcStatus: form.devcStatus,
        devcMassId: form.devcMassId,
        devcDescription: form.devcDescription,
        devcUniqueId: form.devcUniqueId,
      };
      this.errors = {};
      this.isSubmit = true;
      try {
        let response = {};
        if (form.devcId) {
          response = await DeviceSvc.updateDevice(data, form.devcId);
        } else {
          response = await DeviceSvc.addDevice(form);
        }
        if (response.data.status.error === 0) {
          await this.getData('Success');
          this.currentDevice = {};
          this.displayForm = false;
        } else {
          const errors = response.data.status.message;
          if (typeof errors === 'object') {
            this.errors = response.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error(response.data.status.message);
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        this.isSubmit = false;
      }
    },
    onCancel() {
      this.currentDevice = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    onChangeAsset(v) {
      this.filter.devcMassId = v ? v.massId : null;
      this.getData();
    },
    onChangeDeviceType(v) {
      this.filter.devcType = v ? v.value : null;
      this.getData();
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    openAddForm() {
      this.currentDevice = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openEdit(data) {
      this.displayForm = true;
      this.currentDevice = {
        ...this._.find(this.data, (v) => v.devcId === data.devcId),
      };
    },
    openDetail(data) {
      this.$router.push({ name: 'DeviceDetail', params: { id: data.devcId } });
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  async mounted() {
    this.assetFilter.massCompId = Object.keys(
      this.$store.getters['auth/availableCompany'],
    );

    await this.getAssets();
    await this.getData();

    // mengambil data nama kapal berdasar massId
    this.massName = this.assets.find(
      (asset) => asset.massId === parseInt(this.massId),
    ).massName;
  },
};
</script>

<style scoped></style>
