<template>
  <div class="ship-sec" :style="isMenuOpen ? 'bottom: 51%' : 'bottom: 20px'">
    <button @click="toggleMenu" class="menu-button">
      <i class="ri-arrow-up-double-fill" :style="isMenuOpen ? 'transform: rotate(180deg)' : ''"></i>
    </button>
    <div class="ship-menu" v-if="isMenuOpen">
      <div class="menu-search rounded-pill">
        <i class="ri-search-line mr-2"></i>
        <input type="text" class="transparent-input mb-1" placeholder="Search..." @focus="focusInput">
      </div>
      <div class="menu-content row">
        <div class="col-md-6" v-for="item in ships" :key="item.id">
          <div class="menu-card">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h5 @click="zoomToShip(item)" style="cursor: pointer">{{ item.massName }}</h5>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center pt-0">
                <h5 :class="[item.dataStatus ? 'status online' : 'status offline']">{{ item.dataStatus ? 'online' : 'offline' }}</h5>
                <span class="ml-2 change-section" @click="toggleSection(item.id)" style="cursor: pointer">
                  <i class="ri-arrow-drop-right-line" style="font-size: 23px;"></i>
                </span>
              </div>
            </div>
            <div class="row info-section" v-if="selectedId === item.id">
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <b-form-checkbox v-model="item.trail" name="check-button" switch class="mr-2"
                  @change="updateTrail({massId: item.massId, trail: item.trail})"></b-form-checkbox>
                  <p>Vessel Trail</p>
                </div>
              </div>
            </div>
            <div class="row info-section" v-else>
              <div class="col-md-3">
                <p>Location</p>
                <p style="font-weight: 500; color: #1E1E1E">{{ item.location }}</p>
              </div>
              <div class="col-md-3">
                <p>Total Cruise</p>
                <p style="font-weight: 500; color: #1E1E1E">
                {{ item.summary &&
                  item.summary.gps &&
                  item.summary.gps.summary &&
                  (item.summary.gps.summary.totalDistance / 1852).toFixed(2) || '-'
                }} NM</p>
              </div>
              <div class="col-md-3">
                <p>Avg. Speed</p>
                <p style="font-weight: 500; color: #1E1E1E">
                  {{
                    item.summary &&
                    item.summary.gps &&
                    item.summary.gps.summary &&
                    (item.summary.gps.summary.averageSpeed / 1.852).toFixed(2) || '-'
                  }}
                  knot</p>
              </div>
              <div class="col-md-3">
                <p>Last Update</p>
                <p style="font-weight: 500; color: #1E1E1E">{{ formatUnixTimestamp(item.lastGpsData.timestamp) }}</p>
              </div>
            </div>
            <div class="row button-section">
              <div class="col-6 col-sm-12 my-1">
                <b-button block variant="dark" class="tooltip-button" @click="() => handleClickReport(ship.massId)">View Report</b-button>
              </div>
              <div class="col-6 col-sm-12 my-1">
                <b-button block variant="outline-dark" class="tooltip-button" @click="() => handleClickDashboard(ship.massId)">View Dashboard</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShipSection',
  props: {
    ships: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isInputFocused: false,
      selectedId: null,
    };
  },
  methods: {
    async handleClickDashboard(massId) {
      await this.setCurrentMassId(massId);
      await this.$router.push({ name: 'FmsDashboard' });
    },
    async handleClickReport(massId) {
      await this.setCurrentMassId(massId);
      await this.$router.push({ name: 'FmsReport' });
    },
    formatUnixTimestamp(unixTime) {
      const date = new Date(unixTime * 1000);
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      const hour = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);

      return `${day}-${month}-${year} ${hour}:${minutes}`;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleSection(id) {
      if (this.selectedId === id) {
        this.selectedId = null;
      } else {
        this.selectedId = id;
      }
    },
    focusInput() {
      this.isInputFocused = true;
    },
    updateTrail({ massId, trail }) {
      this.$store.dispatch('asset/updateAssetTrail', { massId, trail });
    },
    zoomToShip(item) {
      this.$emit('zoom-to-ship', item);
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>
.ship-sec {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.ship-sec .menu-button {
  background-color: white;
  color: #264284;
  border: none;
  padding: 0px 100px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out;
}

.ship-sec button i {
  font-size: 20px;
}

.ship-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.500);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-y: scroll;
  z-index: 999;
}

.menu-search {
  padding: 10px;
  width: 35%;
  margin-top: 10px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 35px;
  margin-top: 10px;
  background-color: #ebebeb;
}

.menu-search .transparent-input {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 5px;
  margin-left: 10px;
}

.menu-search .transparent-input:focus {
  outline: 0;
}

.menu-search input::placeholder {
  color: #202224;
}

.menu-content {
  padding: 10px;
  height: calc(100% - 70px);
  overflow-y: scroll;
  width: 50%;
  margin: 0 auto;
}

.menu-card {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  border-color: #E8ECF3;
  padding: 25px 16px;
  min-height: 201px;
}

.col-md-6 {
  padding-right: 10px;
}

.status {
  margin-left: 10px;
  padding: 1px 5px;
  border-radius: 5px;
  font-weight: 600;
}

.online {
  background-color: #D7F0D8;
  color: #38AC3D;
}

.offline {
  background-color: #FFE4E4;

  color: #F24848;
}

.card-button {
  width: 100%;
  font-weight: 700;
}

.col-md-3 {
  padding: 0 !important;
}

.info-section {
  padding: 0 15px;
  min-height: 71.6px;
}

p {
  margin-bottom: -2px !important;
}

@media (min-width: 768px) {
  .menu-content {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .menu-content {
    width: 100%;
  }
  .menu-search {
    width: 80%;
  }
}
</style>
