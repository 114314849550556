import axios from 'axios';

export default {
  getSummary({
    devcType,
    devcMassId,
    start,
    end,
    aggregatedUnit,
    showDetails,
  }) {
    return axios.get(`/summary/aggregated/period/${start}/${end}`, {
      params: {
        devcType,
        devcMassId,
        start,
        end,
        aggregatedUnit,
        showDetails,
      },
    });
  },
};
