<template>
  <div class="wrapper">
    <div class="d-flex align-items-center">
      <!-- date filter -->
      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Select Range</span>
        <v-date-picker v-model="dateRange" is-range mode="datetime" :masks="masks" is24hr>
          <template #default="{ inputValue, inputEvents, isDragging }">
            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
              <div class="position-relative flex-grow-1">
                <input class="form-control bg-light border rounded" style="width: 320px;"
                  :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue.start + ' - ' + inputValue.end"
                  v-on="inputEvents.start" />
              </div>
            </div>
          </template>
        </v-date-picker>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Interval</span>
        <select name="" class="form-control" v-model="selectedInterval">
          <option value="minute">Minute</option>
          <option value="hour">Hour</option>
          <option value="day">Day</option>
          <option value="week">Week</option>
        </select>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">Asset</span>
        <select name="" class="form-control" v-model="selectedAsset">
          <option v-for="asset in assets" :key="asset.massId" :value="asset.massId">{{ asset.massName }}</option>
        </select>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
        <button class="btn btn-primary" @click="onApplyFilter">Apply</button>
      </div>

      <div class="ml-3">
        <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
        <button class="btn btn-success" @click="onDownloadExcel">
          Download XLSX
        </button>
      </div>
    </div>

    <div class="wrapper mt-2">
      <div class="d-flex align-items-center">
        <div class="ml-3">
          <span class="d-block text-muted small text-left font-weight-bold mb-2">Speed Unit</span>
          <select name="" class="form-control" v-model="unit.speed">
            <option value="knot">KNOT</option>
            <option value="km/h">KM/H</option>
          </select>
        </div>
        <div class="ml-3">
          <span class="d-block text-muted small text-left font-weight-bold mb-2">Distance Unit</span>
          <select name="" class="form-control" v-model="unit.distance">
            <option value="nm">NM</option>
            <option value="km">KM</option>
          </select>
        </div>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>
    <template v-else-if="currAssetSummary && !isLoading">
      <div class="iq-card mt-3">
        <div class="iq-card-body pt-2 pb-5 mb-5">
          <div class="table-responsive">
            <div style="height: 70vh; overflow-y: auto;">
              <table class="table table-striped table-sm table-hover table-bordered text-center">
                <thead class="" style="position: sticky; top: 0;">
                  <tr>
                    <th class="bg-light" rowspan="2" style="min-width: 200px;">Date Time</th>
                    <th class="bg-light" rowspan="2" style="min-width: 200px;">Coordinate</th>
                    <th class="bg-light" rowspan="2" style="min-width: 120px;">Heading</th>
                    <th class="bg-light" rowspan="2" style="min-width: 120px;">Speed ({{ unit.speed.toUpperCase() }})
                    </th>
                    <th class="bg-light" rowspan="2" style="min-width: 120px;">Cruise ({{ unit.distance.toUpperCase()
                      }})</th>
                    <th class="bg-light" colspan="6">PORT</th>
                    <th class="bg-light" colspan="6">Starboard</th>
                    <th class="bg-light" colspan="2">AE 1</th>
                    <th class="bg-light" colspan="2">AE 2</th>
                    <th class="bg-light" colspan="2">AE 3</th>
                  </tr>
                  <tr>
                    <th class="bg-light" style="min-width: 120px;">RPM</th>
                    <th class="bg-light" style="min-width: 120px;">Run. Hours</th>
                    <th class="bg-light" style="min-width: 150px;">Volume Total In (Liter)</th>
                    <th class="bg-light" style="min-width: 120px;">In FLow (L/H)</th>
                    <th class="bg-light" style="min-width: 120px;">In Temp. (&deg;C)</th>
                    <th class="bg-light" style="min-width: 120px;">IN Density (g/cm&sup3;)</th>
                    <th class="bg-light" style="min-width: 120px;">RPM</th>
                    <th class="bg-light" style="min-width: 120px;">Run. Hours</th>
                    <th class="bg-light" style="min-width: 150px;">Volume Total In (Liter)</th>
                    <th class="bg-light" style="min-width: 120px;">In FLow (L/H)</th>
                    <th class="bg-light" style="min-width: 120px;">In Temp. (&deg;C)</th>
                    <th class="bg-light" style="min-width: 120px;">IN Density (g/cm&sup3;)</th>
                    <th class="bg-light" style="min-width: 120px;">Run. Hours</th>
                    <th class="bg-light" style="min-width: 120px;">Fuel Cons (Liter)</th>
                    <th class="bg-light" style="min-width: 120px;">Run. Hours</th>
                    <th class="bg-light" style="min-width: 120px;">Fuel Cons (Liter)</th>
                    <th class="bg-light" style="min-width: 120px;">Run. Hours</th>
                    <th class="bg-light" style="min-width: 120px;">Fuel Cons (Liter)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in currAssetSummary.series" :key="data.timestamp">
                    <td>{{ moment(data.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td>{{
                      typeof data?.gpsData?.latitude !== 'undefined' && typeof data?.gpsData?.longitude !== 'undefined'
                        ? `${convertDecimalToDMS(data?.gpsData?.latitude, true)},
                      ${convertDecimalToDMS(data?.gpsData?.longitude, false)}`
                        : '-'
                    }}</td>
                    <td>{{ typeof data?.gpsData?.track !== 'undefined' ? data?.gpsData?.track.toFixed(2) : '-' }}&deg;
                    </td>
                    <!-- Speed Column -->
                    <td>{{
                      typeof data?.gpsData?.speed !== 'undefined'
                        ? unit.speed === 'knot'
                          ? (data?.gpsData?.speed * 0.539957).toFixed(2)
                          : data?.gpsData?.speed.toFixed(2)
                        : '-'
                    }}</td>
                    <!-- ./Speed Column -->
                    <!-- Distance/Cruise Column -->
                    <td>{{
                      typeof data?.gpsData?.distance !== 'undefined'
                        ? unit.distance === 'nm'
                          ? (data?.gpsData?.distance * 0.000539957).toFixed(2)
                          : (data?.gpsData?.distance * 0.001).toFixed(2)
                        : '-'
                    }}</td>
                    <!-- ./Distance/Cruise Column -->
                    <td>{{ typeof data?.rpmData?.PORT?.rpm !== 'undefined' ? data?.rpmData?.PORT?.rpm.toFixed(2) : '-'
                      }}
                    </td>
                    <td>
                      {{
                        typeof data?.rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1 !== 'undefined'
                          ? toHHMMSS(data?.rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1 * 60)
                          : '-'
                      }}
                    </td>
                    <td>{{ typeof data?.fmData?.PORT_IN?.volumeInventory !== 'undefined' ?
                      data?.fmData?.PORT_IN?.volumeInventory.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.fmData?.PORT_IN?.volumeFlowrate !== 'undefined' ?
                      data?.fmData?.PORT_IN?.volumeFlowrate.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.fmData?.PORT_IN?.temperature !== 'undefined' ?
                      data?.fmData?.PORT_IN?.temperature.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.fmData?.PORT_IN?.density !== 'undefined' ?
                      data?.fmData?.PORT_IN?.density.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.rpmData?.STARBOARD?.rpm !== 'undefined' ?
                      data?.rpmData?.STARBOARD?.rpm.toFixed(2)
                      : '-' }}</td>
                    <td>{{
                      typeof data?.rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1 !== 'undefined'
                        ? toHHMMSS(data?.rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1 * 60)
                        : '-'
                    }}</td>
                    <td>{{ typeof data?.fmData?.STARBOARD_IN?.volumeInventory != 'undefined' ?
                      data?.fmData?.STARBOARD_IN?.volumeInventory.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.fmData?.STARBOARD_IN?.volumeFlowrate != 'undefined' ?
                      data?.fmData?.STARBOARD_IN?.volumeFlowrate.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.fmData?.STARBOARD_IN?.temperature != 'undefined' ?
                      data?.fmData?.STARBOARD_IN?.temperature.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.fmData?.STARBOARD_IN?.density != 'undefined' ?
                      data?.fmData?.STARBOARD_IN?.density.toFixed(2) : '-' }}</td>
                    <td>{{ typeof data?.aeData?.AE1?.runningSeconds != 'undefined' ?
                      toHHMMSS(data?.aeData?.AE1?.runningSeconds) : "-" }}</td>
                    <td>{{ typeof data?.aeData?.AE1?.fuelConsumption != 'undefined' ?
                      data?.aeData?.AE1?.fuelConsumption.toFixed(2) : "-" }}</td>
                    <td>{{ typeof data?.aeData?.AE2?.runningSeconds != 'undefined' ?
                      toHHMMSS(data?.aeData?.AE2?.runningSeconds) : "-" }}</td>
                    <td>{{ typeof data?.aeData?.AE2?.fuelConsumption != 'undefined' ?
                      data?.aeData?.AE2?.fuelConsumption.toFixed(2) : "-" }}</td>
                    <td>{{ typeof data?.aeData?.AE3?.runningSeconds != 'undefined' ?
                      toHHMMSS(data?.aeData?.AE3?.runningSeconds) : "-" }}</td>
                    <td>{{ typeof data?.aeData?.AE3?.fuelConsumption != 'undefined' ?
                      data?.aeData?.AE3?.fuelConsumption.toFixed(2) : "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <!-- <nav>
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
            </li>
            <li class="page-item" :class="{ active: n === currentPage }" v-for="n in totalPages" :key="n">
              <a class="page-link" href="#" @click.prevent="changePage(n)">{{ n }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
            </li>
          </ul>
        </nav> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { toHHMMSS, generateTimestampsInSeconds } from '@/util/date';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  name: 'FmsReportDatalog',
  data() {
    return {
      unit: {
        distance: 'nm',
        speed: 'knot',
      },
      masks: {
        input: 'DD-MM-YYYY HH:mm',
      },
      isLoading: true,
    };
  },
  async mounted() {
    const interval = this.$store.getters['filter/interval'];
    const date = this.$store.getters['filter/date'];

    let currentMassId = this.$store.getters['filter/currentMassId'];
    let assets = this.$store.getters['asset/assets'];

    if (assets.length === 0) {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      assets = this.$store.getters['asset/assets'];
    }

    if (currentMassId === null) {
      await this.$store.dispatch('filter/setCurrentMassId', assets[0].massId);
      currentMassId = this.$store.getters['filter/currentMassId'];
    }

    await this.$store.dispatch('asset/fetchSummary', {
      start: date.start,
      end: date.end,
      interval,
      massId: currentMassId,
    });
    this.isLoading = false;
  },
  computed: {
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    currAssetSummary() {
      const summary = this.currAsset?.summary || null;
      const mappedSummary = {};

      if (!summary) return null;
      const timestamps = generateTimestampsInSeconds(moment(this.dateRange.start).unix(), moment(this.dateRange.end).unix(), this.interval === 'minute' ? 60 : 3600);

      const roundedGps = {};
      Object.keys(summary.gps?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedGps[roundedKey] = summary.gps?.data[key];
      });

      const roundedRpm = {};
      Object.keys(summary.rpm?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedRpm[roundedKey] = summary.rpm?.data[key];
      });

      const roundedFm = {};
      Object.keys(summary.flowmeter?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedFm[roundedKey] = summary.flowmeter?.data[key];
      });

      const roundedAe = {};
      Object.keys(summary.ae?.data || {}).forEach((key) => {
        const roundedKey = Math.round(key / 60) * 60;
        roundedAe[roundedKey] = summary.ae?.data[key];
      });

      const mappedSeries = timestamps.map((timestamp) => {
        const gpsData = roundedGps[timestamp] || {};
        const rpmData = roundedRpm[timestamp] || {
          STARBOARD: {},
          PORT: {},
        };
        const fmData = roundedFm[timestamp] || {};
        const aeData = roundedAe[timestamp] || {};
        return {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        };
      });

      mappedSummary.series = mappedSeries;
      return mappedSummary;
    },
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
    }),
    selectedInterval: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$store.dispatch('filter/setInterval', val);
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
  },
  methods: {
    toHHMMSS,
    convertDecimalToDMS(decimal, isLatitude) {
      const degrees = Math.floor(Math.abs(decimal));
      const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
      const minutes = Math.floor(minutesDecimal);
      const seconds = Math.round((minutesDecimal - minutes) * 60);

      // eslint-disable-next-line no-nested-ternary
      const direction = decimal >= 0
        ? (isLatitude ? 'N' : 'E')
        : (isLatitude ? 'S' : 'W');

      return `${degrees}° ${minutes}' ${seconds}'' ${direction}`;
    },
    moment(date) {
      return moment(date);
    },
    ...mapActions(['updatekapalTrail']),
    async onApplyFilter() {
      this.isLoading = true;
      try {
        // await this.$store.dispatch('filter/setDate', this.dateRange);
        await this.$store.dispatch('filter/setCurrentMassId', this.selectedAsset || this.currentMassId);
        await this.$store.dispatch('filter/setInterval', this.selectedInterval || this.interval);

        await this.$store.dispatch('asset/fetchSummary', {
          start: this.dateRange.start,
          end: this.dateRange.end,
          interval: this.interval,
          massId: this.currentMassId,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async onDownloadExcel() {
      const getOrDefault = (value, defaultValue = '-') => {
        const returnValue = value !== undefined ? value : defaultValue;
        return returnValue;
      };

      // Map data untuk Excel
      const excelMappedSeries = this.currAssetSummary.series.map((data) => {
        const {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        } = data;

        const formatCoordinate = () => {
          if (getOrDefault(gpsData.latitude) === '-' || getOrDefault(gpsData.longitude) === '-') {
            return '-';
          }
          return `${this.convertDecimalToDMS(gpsData.latitude, true)}, ${this.convertDecimalToDMS(gpsData.longitude, false)}`;
        };

        const formatSpeed = () => {
          const speed = getOrDefault(gpsData.speed);
          if (speed === '-') return '-';
          return this.unit.speed === 'knot' ? speed * 0.539957 : speed;
        };

        const formatDistance = () => {
          const distance = getOrDefault(gpsData.distance);
          if (distance === '-') return '-';
          return this.unit.speed === 'knot' ? distance * 0.539957 : distance * 0.001;
        };

        return {
          DateTime: moment(timestamp * 1000).format('DD-MM-YYYY HH:mm:ss'),
          Coordinate: formatCoordinate(),
          Heading: getOrDefault(gpsData.track),
          Speed: formatSpeed(),
          Distance: formatDistance(),
          PortRpm: getOrDefault(rpmData?.PORT?.rpm),
          PortRunningHours: getOrDefault(rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1),
          PortVolumeInventory: getOrDefault(fmData?.PORT_IN?.volumeInventory),
          PortInFlow: getOrDefault(fmData?.PORT_InFlow),
          PortInTemp: getOrDefault(fmData?.PORT_IN?.temperature),
          PortInDensity: getOrDefault(fmData?.PORT_IN?.density),
          StbRpm: getOrDefault(rpmData?.STARBOARD?.rpm),
          StbRunningHours: getOrDefault(rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1),
          StbVolumeInventory: getOrDefault(fmData?.STARBOARD_IN?.volumeInventory),
          StbInFlow: getOrDefault(fmData?.STARBOARD_InFlow),
          StbInTemp: getOrDefault(fmData?.STARBOARD_IN?.temperature),
          StbInDensity: getOrDefault(fmData?.STARBOARD_IN?.density),
          AE1RunningHours: getOrDefault(aeData?.AE1?.runningSeconds) !== '-' ? toHHMMSS(aeData.AE1.runningSeconds) : '-',
          AE1TotalFuelUsed: getOrDefault(aeData?.AE1?.fuelConsumption),
          AE2RunningHours: getOrDefault(aeData?.AE2?.runningSeconds) !== '-' ? toHHMMSS(aeData.AE2.runningSeconds) : '-',
          AE2TotalFuelUsed: getOrDefault(aeData?.AE2?.fuelConsumption),
          AE3RunningHours: getOrDefault(aeData?.AE3?.runningSeconds) !== '-' ? toHHMMSS(aeData.AE3.runningSeconds) : '-',
          AE3TotalFuelUsed: getOrDefault(aeData?.AE3?.fuelConsumption),
        };
      });

      // Membuat workbook dan worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Asset Summary');

      // Menambahkan header
      worksheet.columns = [
        { header: 'DateTime', key: 'DateTime', width: 20 },
        { header: 'Coordinate', key: 'Coordinate', width: 30 },
        { header: 'Heading', key: 'Heading', width: 10 },
        { header: `Speed (${this.unit.speed.toUpperCase()})`, key: 'Speed', width: 13 },
        { header: `Distance (${this.unit.distance.toUpperCase()})`, key: 'Distance', width: 13 },
        { header: 'Port Rpm', key: 'PortRpm', width: 10 },
        { header: 'Port Running Hours', key: 'PortRunningHours', width: 20 },
        { header: 'Port Volume Inventory (liter)', key: 'PortVolumeInventory', width: 25 },
        { header: 'Port In Flow (l/h)', key: 'PortInFlow', width: 15 },
        { header: 'Port In Temp (°C)', key: 'PortInTemp', width: 15 },
        { header: 'Port In Density (g/cm³)', key: 'PortInDensity', width: 20 },
        { header: 'Stbd Rpm', key: 'StbRpm', width: 10 },
        { header: 'Stbd Running Hours', key: 'StbRunningHours', width: 20 },
        { header: 'Stbd Volume Inventory (liter)', key: 'StbVolumeInventory', width: 25 },
        { header: 'Stbd In Flow (l/h)', key: 'StbInFlow', width: 15 },
        { header: 'Stbd In Temp (°C)', key: 'StbInTemp', width: 15 },
        { header: 'Stbd In Density (g/cm³)', key: 'StbInDensity', width: 20 },
        { header: 'AE1 Running Hours', key: 'AE1RunningHours', width: 20 },
        { header: 'AE1 Total Fuel Used', key: 'AE1TotalFuelUsed', width: 20 },
        { header: 'AE2 Running Hours', key: 'AE2RunningHours', width: 20 },
        { header: 'AE2 Total Fuel Used', key: 'AE2TotalFuelUsed', width: 20 },
        { header: 'AE3 Running Hours', key: 'AE3RunningHours', width: 20 },
        { header: 'AE3 Total Fuel Used', key: 'AE3TotalFuelUsed', width: 20 },
      ];

      // Memasukkan data ke worksheet
      excelMappedSeries.forEach((row) => {
        worksheet.addRow(row);
      });

      const filename = `${this.currAsset?.massName} - ${moment(this.dateRange.start).format('DD-MM-YYYY HH:mm')} - ${moment(this.dateRange.end).format('DD-MM-YYYY HH:mm')}`;

      // Menyimpan file Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${filename}.xlsx`);
    }
    ,
  },
};

</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-item {
  /* padding: 10px 20px; */
  margin: 0 5px;
}

@media screen and (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }

  .flex-item {
    width: 100%;
  }
}
</style>
