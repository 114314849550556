<template>
  <b-container fluid>
    <div class="iq-card mt-3 px-3">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title py-3">
          <h4 class="card-title">User List</h4>
        </div>
        <b-row>
          <b-col md="12">
            <div class="text-right">
              <button-options :access="'master_user'" :hideAdd="false" :hideDetail="true" :isRefreshEnabled="true"
                :isRefreshed="isRefreshed" @onAddItemClicked="openAddForm" @onRefreshed="getData('Refreshed')" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="iq-card-body">
        <b-row>
          <b-col md="12">
            <user-list :access="'master_user'" :data="data" :loading="isLoading" :pagination="pagination"
              @onActionDelete="openDelete" @onActionEdit="openEdit" @onActionView="openDetail"
              @onPageChanged="onPageChanged" @update="onUpdated" />
          </b-col>
        </b-row>
      </div>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayForm">
        <user-form :data="currentUser" :errors="errors" :isSubmit="isSubmit" @onSubmitClicked="onSubmitClicked"
          @onCancelAddClicked="onCancel" />
      </b-modal>
      <b-modal size="lg" hide-footer no-close-on-esc no-close-on-backdrop v-model="displayDeletion">
        <delete-item :isSubmit="isSubmit" @onCancelDeletion="displayDeletion = false"
          @onConfirmDeletion="onConfirmDeletion" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import DeleteItem from '@/components/directives/delete-item';
import ButtonOptions from '@/components/directives/button-options';
import UserList from '@/components/userrole/user/List';
import UserForm from '@/components/userrole/user/Form';
import UserSvc from '@/services/UserSvc';

import requestBuilder from '@/util/request.builder';

export default {
  name: 'User',
  components: {
    DeleteItem, UserForm, UserList, ButtonOptions,
  },
  data() {
    return {
      currentUser: {},
      data: [],
      displayDeletion: false,
      displayForm: false,
      errors: {},
      filter: {},
      isLoading: false,
      isRefreshed: false,
      isSubmit: false,
      pagination: {
        current: 1,
        pageSize: 15,
        totalSize: 0,
      },
    };
  },
  methods: {
    async deleteData(notification, data) {
      this.isSubmit = true;
      try {
        const userRes = await UserSvc.delete(data.userId);
        const usloRes = await UserSvc.deleteUserLogin(data.userId);
        if (userRes.data.status.error === 0 && usloRes.data.status.error === 0) {
          await this.$toast.success(notification);
          this.displayDeletion = false;
          this.pagination.current = 1;
          this.getData();
        } else {
          this.$toast.error(userRes.data.status.message);
        }
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.isSubmit = false;
      }
    },
    async getData(notification) {
      try {
        this.isLoading = true;
        this.isRefreshed = true;
        console.time('getData');
        const resp = await UserSvc.getUsers({
          params: {
            ...requestBuilder.buildFilters(this.pagination, this.filter),
          },
        });
        const userData = resp.data.data;

        const buildedUserData = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const user of userData) {
          // eslint-disable-next-line no-await-in-loop
          const userLogin = await UserSvc.getUserLogin({ findField: 'usloUserId', findValue: user.userId });
          const usloUserName = userLogin.data.data.length > 0 ? userLogin.data.data[0].usloUserName : '-';
          buildedUserData.push({ ...user, usloUserName });
        }

        this.data = buildedUserData;

        this.pagination.totalSize = resp.data.pagination.totalData;
        if (notification) {
          this.$toast.success(notification);
        }
      } catch (e) {
        if (e.response.data) {
          if (e.response.data.status.message === 'Authentication Error') {
            this.doLogOut();
          } else {
            this.$toast.error(e.response.data.status.message);
          }
        } else {
          this.$toast.error('Network error');
        }
      } finally {
        this.isLoading = false;
        this.isRefreshed = false;
        console.timeEnd('getData');
      }
    },
    onCancel() {
      this.currentUser = {};
      this.displayForm = false;
    },
    onConfirmDeletion() {
      this.deleteData('Deleted complete', this.selected);
    },
    onPageChanged(page) {
      this.pagination.current = page;
      this.getData();
    },
    onUpdated() {
      this.getData('Updated');
    },
    async onSubmitClicked(form) {
      try {
        this.errors = {};
        this.isSubmit = true;

        const userData = {
          userId: form.userId,
          userStatus: form.userStatus,
        };
        const userRes = await UserSvc.create(userData);

        const usloData = {
          usloUserId: form.userId,
          usloUserName: form.usloUserName,
          usloPassword: form.usloPassword,
        };
        const usloRes = await UserSvc.createUserLogin(usloData);

        // let resp = {};
        // if (form.userCreatedTime) {
        //   resp = await UserSvc.update(form.userId, data);
        // } else {
        //   resp = await UserSvc.create(form);
        // }

        if (usloRes.data.status.error === 0 && userRes.data.status.error === 0) {
          await this.getData('Success');
          this.currentUser = {};
          this.displayForm = false;
        } else {
          const errors = userRes.data.status.message;
          if (typeof errors === 'object') {
            // this.errors = resp.data.status.message;
            this.$toast.error('Error found');
          } else {
            this.$toast.error('Error when saving data');
          }
        }
        this.isSubmit = false;
      } catch (e) {
        this.$toast.error(e.response.data.status.message);
        this.isSubmit = false;
      }
    },
    openAddForm() {
      this.currentUser = {};
      this.displayForm = true;
    },
    openDelete(data) {
      this.displayDeletion = true;
      this.selected = data;
    },
    openDetail(data) {
      this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    openEdit(data) {
      // this.displayForm = true;
      // this.currentUser = { ...this._.find(this.data, (v) => v.userId === data.userId) };
      this.$router.push({ name: 'UserDetail', params: { id: data.userId } });
    },
    doLogOut() {
      this.$toast.error('Please re-login');
      this.$store.dispatch('auth/logout');
      this.$router.replace({ name: 'Login' });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped></style>
