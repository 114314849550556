<template>
  <div class="iq-card mb-0" style="min-width: 300px">
    <div class="iq-card-header d-flex justify-content-between">
      <div class="iq-header-title">
        <h4 class="card-title">
          {{ data.massId ? 'Edit Asset' : 'Add New Asset'}} for {{ activeCompany.compName }}
        </h4>
      </div>
    </div>
    <div class="iq-card-body">
      <div class="row">
        <div role="group" class="form-group col-md-6">
          <label for="massImage" class="d-block form-label text-left">
            Image:
          </label>
          <div class="bv-no-focus-ring">
            <b-form-file
              id="massImage"
              class="mb-2"
              placeholder="Choose a file or drag and drop it here..."
              drop-placeholder="Drop file here..."
              accept=".jpeg,.jpg,.png"
              @change="previewImage"
            />
          </div>
          <img
            v-if="imagePreview"
            :src="imagePreview"
            alt="Selected Image"
            class="img-thumbnail mt-2"
            style="max-width: 100%; height: auto;"
          />
        </div>
        <div
          v-for="(field, index) in fields"
          :key="index"
          role="group"
          class="form-group col-md-6"
        >
          <label :for="field.Label" class="d-block form-label text-left">
            {{ field.Label }}:
          </label>
          <div class="bv-no-focus-ring">
            <div role="group" class="input-group">
              <!-- Select (enum) -->
              <b-form-select
                v-if="field.Type.includes('enum')"
                class="form-control"
                v-model="data[field.Field]"
                :id="field.Field"
                :placeholder="'Enter ' + field.Label"
                :class="errors[field.Field] ? 'is-invalid' : ''"
                :disabled="isSubmit"
                :options="
                  field.Type.replace(/^enum\(|\)$/g, '')
                    .split(',')
                    .map((item) => item.replace(/'/g, '').trim())
                    .map((item) => ({ text: item, value: item }))
                "
              />
              <!-- Input type 'date' -->
              <input
                v-else-if="field.Type.includes('date')"
                autocomplete="off"
                class="form-control"
                type="date"
                v-model="data[field.Field]"
                :id="field.Field"
                :placeholder="'Enter ' + field.Label"
                :class="errors[field.Field] ? 'is-invalid' : ''"
                :disabled="isSubmit"
              />
              <!-- Input type 'text' -->
              <input
                v-else
                autocomplete="off"
                class="form-control"
                type="text"
                v-model="data[field.Field]"
                :id="field.Field"
                :placeholder="'Enter ' + field.Label"
                :class="errors[field.Field] ? 'is-invalid' : ''"
                :disabled="isSubmit"
              />
              <b-form-invalid-feedback v-if="errors[field.Field]">
                {{ Object.values(errors[field.Field]) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <b-button
          @click="cancel"
          class="mr-1"
          size="sm"
          variant="outline-danger"
        >
          <div class="mx-2">Cancel</div>
        </b-button>
        <b-button
          class="ml-1"
          size="sm"
          type="submit"
          variant="primary"
          @click="handleSubmit"
          :disabled="isSubmit"
        >
          <div class="mx-2">
            <i v-if="isSubmit" class="ri-loader-line"></i>
            Submit
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import AssetSvc from '@/services/AssetSvc';

export default {
  name: 'AssetForm',
  computed: {
    activeCompany() {
      return this.$store.getters['auth/activeCompany'];
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fields: {
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      isSubmit: false,
      imagePreview: null,
      imageFile: null, // Data property to store the selected image file
    };
  },
  methods: {
    async handleSubmit() {
      try {
        this.isSubmit = true;
        const compId = this.activeCompany.compId;
        const massId = this.data.massId || undefined; // undefined artinya data baru
        const formData = { ...this.data, massCompId: compId, massDataId: compId };

        // menghapus data yang tidak diperlukan untuk PUT master Asset
        if (massId) {
          delete formData.massDevice;
          delete formData.massImage;
        }

        const assetResponse = await AssetSvc.addOrUpdateAsset(massId, formData);
        if (assetResponse.data.status.error === 0) {
          console.debug('assetResponse : ', assetResponse);

          // pakai massId untuk upload gambar atau gunakan assetResponse.data.id jika data baru
          const assetId = massId || assetResponse.data.id;

          if (this.imageFile) {
            const uploadResponse = await AssetSvc.uploadAssetImages(assetId, this.imageFile);
            console.debug('uploadResponse : ', uploadResponse);
          }

          this.$toast.success('New asset created');
          this.$emit('onSuccess');
        } else if (assetResponse.data.status.errorCode === 401) {
          this.errors = assetResponse.data.status.message;
          this.$toast.error('Invalid data');
        }
      } catch (e) {
        this.$toast.error(e.response?.data?.status?.message || 'An error occurred');
      } finally {
        this.isSubmit = false;
      }
    },
    cancel() {
      this.$emit('onCancel');
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.imagePreview = URL.createObjectURL(file);
        this.imageFile = file; // Store the selected file for uploading
      } else {
        this.imagePreview = null;
        this.imageFile = null;
      }
    },
  },
  watch: {
    fields() {
      console.debug('fields', this.fields);
    },
    data() {
      console.debug('data', this.data);
    },
  },
};
</script>

<style scoped>
.vs__dropdown-toggle {
  border: 0;
}

.vs__selected {
  color: #8c91b6;
}
</style>
