<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="table-responsive">
    <b-table
      striped
      hover
      fixed
      head-variant="dark"
      show-empty
      responsive
      :busy="loading"
      :fields="fields"
      :items="canRead(access) ? data : []"
      style="border-radius: 10px; overflow: scroll; max-height: 500px;"
    >
      <template v-slot:table-busy="">
        <div class="text-center text-danger my-2">
          <b-spinner variant="success" size="sm" class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:cell(userStatus)="data">
        <b-badge v-if="data.value === 1" variant="success">Active</b-badge>
        <b-badge v-else variant="danger">Inactive</b-badge>
      </template>
      <template v-slot:cell(userCreatedTime)="data">
        {{dateWithDDMMYYYYFormat(data.value)}}
      </template>
      <template v-slot:cell(role)="data">
        {{data.value ? data.value : '-'}}
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-center">
          <user-edit
            :access="'master_device'"
            :user="data.item"
            @onDeleteClicked="onActionDelete"
            @onViewClicked="onActionView"
            @onEditClicked="onActionEdit"/>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="pagination.current"
      :per-page="pagination.pageSize"
      :total-rows="pagination.totalSize"
      @change="onPaginationChanged"
    ></b-pagination>
  </div>
</template>

<script>
import moment from 'moment';
import UserEdit from './Edit';

export default {
  name: 'UserList',
  components: { UserEdit },
  props: ['access', 'data', 'group', 'loading', 'pagination'],
  data() {
    return {
      fields: [
        {
          key: 'userId',
          label: 'Email',
          class: 'text-left',
        },
        {
          key: 'usloUserName',
          label: 'Username',
          class: 'text-left',
        },
        {
          key: 'userStatus',
          label: 'Status',
          class: 'text-center',
        },
        {
          key: 'userCreatedTime',
          label: 'Created',
          class: 'text-center',
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
        },
      ],
    };
  },
  methods: {
    dateWithDDMMYYYYFormat(v) {
      return moment(v).format('DD MMMM YYYY');
    },
    onPaginationChanged(page) {
      this.$emit('onPageChanged', page);
    },
    onActionDelete(data) {
      this.$emit('onActionDelete', data);
    },
    onActionEdit(data) {
      this.$emit('onActionEdit', data);
    },
    onActionView(data) {
      this.$emit('onActionView', data);
    },
  },
};
</script>

<style scoped>

</style>
