<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 80%"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
    ref="map"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-feature-group ref="featureGroup">
      <template v-for="(ship, index) in assets">
        <!-- Ship Trail -->
        <l-polyline
          v-if="ship.trail"
          :key="index"
          :lat-lngs="ship.coordinates"
          :weight="4"
          :smoothFactor="2"
          :color="ship.dataStatus ? '#16B961' : '#FF3B30'"
        />
        <!-- ./Ship Trail -->

        <!-- Ship Dot Trail -->
        <template v-if="ship.trail && ship.dotTrail">
          <v-rotated-marker
            v-for="(coordinate, index) in ship.coordinatesData"
            :key="index"
            :lat-lng="coordinate.latLong"
            :icon="ship.markerIcon"
            :rotationAngle="coordinate.heading"
          >
            <l-popup>
              <!-- Paginated Info Section -->
              <div class="row">
                <div class="col-12">
                  <p style="margin: 0; font-size: 14px"><b>Datetime: </b>{{ coordinate.dateTime }}</p>
                </div>
              </div>
              <hr />
              <div class="row info-section pb-2">
                <template v-if="currentPopupPage === 1">
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Location</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      -
                    </p>
                  </div>
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px">Total Cruise</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      {{ (coordinate.totalCruise / 1852).toFixed(2) }} NM
                    </p>
                  </div>
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px">Avg. Speed</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      {{ (coordinate.avgSpeed / 1.852).toFixed(2) }} Knot
                    </p>
                  </div>
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px">Current AE</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      AE {{ coordinate.currentAe }}
                    </p>
                  </div>
                </template>
                <template v-else-if="currentPopupPage === 2">
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Port RPM</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      {{ (coordinate.portRpm).toFixed(2) }}
                    </p>
                  </div>
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px">Starboard RPM</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      {{ (coordinate.starboardRpm).toFixed(2) }}
                    </p>
                  </div>
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px">Port In Flowrate</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      {{ (coordinate.portInFlowrate).toFixed(2) }} L/h
                    </p>
                  </div>
                  <div class="col-6" style="height: 50px">
                    <p style="margin-top: 0; margin-bottom: -10px">Starboard In Flowrate</p>
                    <p style="font-weight: 1000; color: #1e1e1e">
                      {{ (coordinate.starboardInFlowrate).toFixed(2) }} L/h
                    </p>
                  </div>
                </template>
              </div>
              <hr />
              <div class="row">
                <div class="col-12 d-flex justify-content-end">
                  <button
                    class="btn btn-sm btn-outline-mantraz mr-1"
                    @click="previousPopupPage"
                    :disabled="currentPopupPage === 1"
                  >
                    Previous
                  </button>
                  <button
                    class="btn btn-sm btn-outline-mantraz"
                    @click="nextPopupPage"
                    :disabled="currentPopupPage === totalPopupPages"
                  >
                    Next
                  </button>
                </div>
              </div>
            </l-popup>
          </v-rotated-marker>
        </template>
        <!-- ./Ship Dot Trail -->

        <v-rotated-marker
          v-if="ship.lastGpsData"
          :key="index"
          :lat-lng="ship.lastLatLng"
          :icon="ship.icon"
          class="ship"
          :rotationAngle="ship.lastGpsData.track"
        >
          <l-popup>
            <div class="row pt-3">
              <div class="col-6 d-flex align-items-center">
                <h5>{{ ship.massName }}</h5>
              </div>
              <div class="col-6 d-flex justify-content-end align-items-center pt-0">
                <h5 :class="[ship.dataStatus ? 'status online' : 'status offline']">
                  {{ ship.dataStatus ? 'online' : 'offline' }}
                </h5>
              </div>
            </div>
            <hr />
            <!-- Paginated Info Section -->
            <div class="row">
              <div class="col-12">
                <p style="margin: 0; font-size: 14px"><b>Last Data: </b> {{ formatUnixTimestamp(ship.lastGpsData.timestamp) }}</p>
              </div>
            </div>
            <hr />
            <div class="row info-section pb-2">
              <template v-if="currentPopupPage === 1">
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Location</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{ ship.location }}
                  </p>
                </div>
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px">Total Cruise</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{
                      ship.summary &&
                      ship.summary.gps &&
                      ship.summary.gps.summary &&
                      (ship.summary.gps.summary.totalDistance / 1852).toFixed(2) || '-'
                    }} NM
                  </p>
                </div>
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px">Avg. Speed</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{
                      ship.summary &&
                        ship.summary.gps &&
                        ship.summary.gps.summary &&
                        (ship.summary.gps.summary.averageSpeed / 1.852).toFixed(2) || '-'
                    }} knot
                  </p>
                </div>
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px">Current AE</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    AE {{ ship.lastAeData.find((ae) => ae.rpm > 0) ? ship.lastAeData.find((ae) => ae.rpm > 0).no + 1 : '-' }}
                  </p>
                </div>
              </template>
              <template v-else-if="currentPopupPage === 2">
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Port RPM</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{ Math.floor(ship.lastRpmData.find((rpm) => rpm.type === 'PORT').rpm) }}
                  </p>
                </div>
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px">Starboard RPM</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{ Math.floor(ship.lastRpmData.find((rpm) => rpm.type === 'STARBOARD').rpm) }}
                  </p>
                </div>
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px">Port In Flowrate</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{ Math.floor(ship.lastFmData.find((fm) => {
                        const deviceIdArray = fm.deviceId.split('-');
                        return deviceIdArray[deviceIdArray.length - 1] === '1';
                      }).volumeFlowrate)
                    }} L/h
                  </p>
                </div>
                <div class="col-6" style="height: 50px">
                  <p style="margin-top: 0; margin-bottom: -10px">Starboard In Flowrate</p>
                  <p style="font-weight: 1000; color: #1e1e1e">
                    {{ Math.floor(ship.lastFmData.find((fm) => {
                        const deviceIdArray = fm.deviceId.split('-');
                        return deviceIdArray[deviceIdArray.length - 1] === '3';
                      }).volumeFlowrate)
                    }} L/h
                  </p>
                </div>
              </template>
            </div>
            <hr />
            <div class="row pb-3">
              <div class="col-6" style="height: 15px">
                <b-form-checkbox
                  v-model="ship.trail"
                  name="check-button"
                  switch
                  class="mr-2"
                  @change="updateTrail({ massId: ship.massId, trail: ship.trail })"
                >Vessel Trail</b-form-checkbox>
                <b-form-checkbox
                  v-if="ship.trail"
                  v-model="ship.dotTrail"
                  name="check-button"
                  switch
                  class="mr-2"
                  @change="updateDotTrail({ massId: ship.massId, dotTrail: ship.dotTrail })"
                >Dot Trail</b-form-checkbox>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <button
                  class="btn btn-sm btn-outline-mantraz mr-1"
                  @click="previousPopupPage"
                  :disabled="currentPopupPage === 1"
                >
                  Previous
                </button>
                <button
                  class="btn btn-sm btn-outline-mantraz"
                  @click="nextPopupPage"
                  :disabled="currentPopupPage === totalPopupPages"
                >
                  Next
                </button>
              </div>
            </div>
            <hr />
            <div class="row button-section">
              <div class="col-6 my-1">
                <button class="btn btn-sm btn-block btn-mantraz tooltip-button" @click="() => handleClickReport(ship.massId)">View Report</button>
              </div>
              <div class="col-6 my-1">
                <button class="btn btn-sm btn-block btn-outline-mantraz tooltip-button" @click="() => handleClickDashboard(ship.massId)">View Dashboard</button>
              </div>
            </div>
          </l-popup>
          <l-tooltip :options="tooltipOptions">
            <span v-if="ship.dataStatus && ship.lastGpsData" class="ship-label ship-online">{{ ship.massName }}</span>
            <span v-if="!ship.dataStatus && ship.lastGpsData" class="ship-label ship-offline">{{ ship.massName }}</span>
          </l-tooltip>
        </v-rotated-marker>
      </template>

    </l-feature-group>
    <l-control-polyline-measure :options="polylineMeasureOptions" />
  </l-map>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LPopup,
  LFeatureGroup,
  LTooltip,
  LPolyline,
} from 'vue2-leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';
import { mapActions } from 'vuex';

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LFeatureGroup,
    LTooltip,
    LPolyline,
    LControlPolylineMeasure,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
  },
  props: {
    ships: {
      type: Array,
      required: true,
    },
    zoom: {
      type: null,
    },
    center: {
      type: null,
    },
  },
  data() {
    return {
      url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      attribution:
        'Map data © <a href="https://www.google.com/maps">Google Maps</a>',
      tooltipOptions: {
        permanent: true,
        directions: 'auto',
        sticky: false,
        fixed: false,
      },
      mapOptions: {
        zoomSnap: 0.5,
        attributionControl: false,
      },
      polylineMeasureOptions: {
        position: 'topleft',
        unit: 'nautical miles',
        useSubunits: true,
        clearMeasurementsOnStop: false,
        showBearings: true,
        bearingTextIn: 'In',
        bearingTextOut: 'Out',
        tooltipTextFinish: 'Click to <b>finish line</b><br>',
        tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
        tooltipTextMove: 'Click and drag to <b>move point</b><br>',
        tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
        tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
        measureControlTitleOn: 'Turn on PolylineMeasure',
        measureControlTitleOff: 'Turn off PolylineMeasure',
        measureControlLabel: '&#8614;',
        measureControlClasses: [],
        showClearControl: true,
        clearControlTitle: 'Clear Measurements',
        clearControlLabel: '&times',
        clearControlClasses: [],
        showUnitControl: true,
        unitControlUnits: ['kilometres', 'landmiles', 'nauticalmiles'],
        unitControlTitle: {
          text: 'Change Units',
          kilometres: 'kilometres',
          landmiles: 'land miles',
          nauticalmiles: 'nautical miles',
        },
        unitControlLabel: {
          metres: 'm',
          kilometres: 'km',
          feet: 'ft',
          landmiles: 'mi',
          nauticalmiles: 'nm',
        },
        unitControlClasses: [],
        tempLine: {
          color: '#00f',
          weight: 2,
        },
        fixedLine: {
          color: '#006',
          weight: 2,
        },
        arrow: {
          color: '#000',
        },
        startCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#0f0',
          fillOpacity: 1,
          radius: 3,
        },
        intermedCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#ff0',
          fillOpacity: 1,
          radius: 3,
        },
        currentCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#f0f',
          fillOpacity: 1,
          radius: 6,
        },
        endCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#f00',
          fillOpacity: 1,
          radius: 3,
        },
      },
      currentPopupPage: 1,
      totalPopupPages: 2,
    };
  },
  computed: {
    assets() {
      return this.ships;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fitMapToBounds();
    });
  },
  methods: {
    ...mapActions(['setCurrentMassId']),
    async handleClickDashboard(massId) {
      await this.setCurrentMassId(massId);
      await this.$router.push({ name: 'FmsDashboard' });
    },
    async handleClickReport(massId) {
      await this.setCurrentMassId(massId);
      await this.$router.push({ name: 'FmsReport' });
    },
    formatUnixTimestamp(unixTime) {
      const date = new Date(unixTime * 1000);
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear();
      const hour = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);

      return `${day}-${month}-${year} ${hour}:${minutes}`;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    updateTrail({ massId, trail }) {
      this.$store.dispatch('asset/updateAssetTrail', { massId, trail });
    },
    updateDotTrail({ massId, dotTrail }) {
      this.$store.dispatch('asset/updateAssetDotTrail', { massId, dotTrail });
    },
    fitMapToBounds() {
      if (this.$refs.map) {
        const map = this.$refs.map.mapObject;
        const group = this.$refs.featureGroup.mapObject;
        if (group) {
          map.fitBounds(group.getBounds(), {
            padding: [50, 50],
          });

          if (map.getZoom() > 12) {
            map.setZoom(12);
          } else {
            map.setZoom(map.getZoom());
          }
        }
      }
    },
    nextPopupPage() {
      if (this.currentPopupPage < this.totalPopupPages) this.currentPopupPage++;
    },
    previousPopupPage() {
      if (this.currentPopupPage > 1) this.currentPopupPage--;
    },
  },
};
</script>

<style>
.ship-label {
  font-size: 13px;
  font-weight: 1000;
  text-align: center;
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 5px;
}

.ship-online {
  background-color: #16b961;
}

.ship-offline {
  background-color: #ff3b30;
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #ffffff !important;
  color: #1e1e1e;
  font-weight: 500;
  width: 330px;
  padding: 10px;
}

.status {
  margin-left: 10px;
  padding: 1px 5px;
  border-radius: 5px;
  font-weight: 600;
}

.online {
  background-color: #d7f0d8;
  color: #38ac3d;
}

.offline {
  background-color: #ffe4e4;

  color: #f24848;
}

.tooltip-button {
  width: 100%;
  font-weight: 700;
}

hr {
  margin: 0;
}

.btn-outline-mantraz {
  border-color: #ff69b4 !important;
  color: #ff69b4 !important;
}

.btn-outline-mantraz:hover {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

.btn-mantraz {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

</style>
