import axios from 'axios';

export default {
  async getReports(start, end, massId, reportType = []) {
    const response = await axios.get(`/reports/report/print/period/${start}/${end}`, {
      params: {
        devcMassId: massId,
        aggregatedUnit: 'HOUR',
        aggregatedLength: 1,
        reports: reportType,
        dataTable: true,
      },
      responseType: 'blob',
    });

    return {
      blob: response.data,
      filename: response.headers['content-disposition'].split('filename="')[1].split('"')[0],
    };
  },
};
